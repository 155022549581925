<template>
  <div>
    <v-file-input truncate-length="15" @change="uploadFile"></v-file-input>
    <v-btn @click="onUploadButtonClick">
      Bulk Upload
    </v-btn>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    name:"bulkUpload",
    data() {
        return {
            file:null
        }
    },
    computed: {
    ...mapState("trainerProfile", {
      profile: "profile",
      loading: "loading",
      editMode: "editMode"
    })
  },
    methods: {
        uploadFile(file){
            console.log('file ' ,file);
            this.file=file
        },
     async onUploadButtonClick(){
         
            console.log('upload button clicked' , this.file);
            this.$store.dispatch('trainerProfile/bulkUpload', this.file)
            
            }
            }
}
</script>

<style></style>
